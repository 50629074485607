const sections = {
  sections: {
    hpSection: {
      py: [5, null, null, 6],
      bg: 'white'
    }
  }
}

export default sections
